import React from 'react'
import SuccessIcon from '../../../assets/svgs/svg-icons/SuccessIcon'

const ServicesModalSelector = ({ selected, toggle, provider}) => {
    return (
    <div className={selected ? 'selected services-modal-selector' : 'services-modal-selector'} onClick={toggle} >
        {provider}
       {selected && (<SuccessIcon />) }
    </div>
  )
}

export default ServicesModalSelector