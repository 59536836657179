// Selector to get the entire providers state
export const selectProvidersState = (state) => state.provider;

// Selector to get the loading status for providers actions
export const selectProvidersLoading = (state) => state.provider.loading;

// Selector to get the error for providers actions
export const selectProvidersError = (state) => state.provider.error;

// Selector to get the list of providers (includes filtered or search results)
export const selectProvidersData = (state) => state.provider?.providers?.data?? [];

// Selector to get the current page for pagination
export const selectProvidersCurrentPage = (state) => state.provider.providers.currentPage;

// Selector to get the total number of pages
export const selectProviderstotalRecords = (state) => state.provider.providers.totalRecords;

// Selector to get the number of items per page
export const selectProvidersPageSize = (state) => state.provider.providers.pageSize;

// Selector to get the loading status for export action
export const selectExportLoading = (state) => state.provider.export.loading;

// Selector to get the success status for export action
export const selectExportSuccess = (state) => state.provider.export.success;

// Selector to get the error for export action
export const selectExportError = (state) => state.provider.export.error;

// Selector to get the provider details
export const selectProviderDetails = (state) => state.provider.viewDetails.data;

// Selector to get the error for provider details action
export const selectProviderDetailsError = (state) => state.provider.viewDetails.error;

// Selector to get the success status for updating provider info
export const selectUpdateInfoSuccess = (state) => state.provider.updateInfo.success;

// Selector to get the error for updating provider info action
export const selectUpdateInfoError = (state) => state.provider.updateInfo.error;

// Selector to get the success status for updating provider balance
export const selectUpdateBalanceSuccess = (state) => state.provider.updateBalance.success;

// Selector to get the error for updating provider balance action
export const selectUpdateBalanceError = (state) => state.provider.updateBalance.error;

export const selectUpdateBalanceLoading = (state) => state.provider.updateBalance.loading;

// Selector to get the success status for adding provider
export const selectAddProviderSuccess = (state) => state.provider.addProvider.success;

// Selector to get the error for adding provider action
export const selectAddProviderError = (state) => state.provider.addProvider.error;
