// api/user.js

import apiClient from './apiClient';

// Request to list users
export const listUsersRequest = (payload) => {
  const {nextPage = 1, ...rest} = payload || {};

  return apiClient({
    method: 'POST',
    url: `/customers?page=${nextPage}`,
    data: rest
  });
}

// Request to filter users
export const filterUsersRequest = (filters) =>
  apiClient({
    method: 'POST',
    url: '/users/filter',
    data: filters,
  });

// Request to search users
export const searchUsersRequest = (query) =>
  apiClient({
    method: 'GET',
    url: '/users/search',
    params: { query },
  });

// Request to export users
export const exportUsersRequest = (format) =>
  apiClient({
    method: 'GET',
    url: '/users/export',
    params: { format },
  });

// Request to block a user
export const blockUserRequest = (id) =>
  apiClient({
    method: 'POST',
    url: `/users/${id}/block`,
  });

// Request to unblock a user
export const unblockUserRequest = (id) =>
  apiClient({
    method: 'POST',
    url: `/users/${id}/unblock`,
  });

// Request to delete a user
export const deleteUserRequest = (id) =>
  apiClient({
    method: 'DELETE',
    url: `/users/${id}`,
  });

// Request to update user details
export const updateUserRequest = (id, data) =>
  apiClient({
    method: 'PUT',
    url: `/users/${id}`,
    data,
  });

// Request to get user info
export const getUserInfoRequest = (id) =>
  apiClient({
    method: 'GET',
    url: `/users/${id}`,
  });

// Request to update wallet balance
export const updateWalletBalanceRequest = (data) => {
  const {id, ...body} = data || {};

  return apiClient({
    method: 'POST',
    url: `/customers/${id}/balance`,
    data: body,
  });
}

// Request to update user commission
export const updateUserCommissionRequest = (id, data) =>
  apiClient({
    method: 'PUT',
    url: `/users/${id}/commission`,
    data,
  });

// Request to list user commissions
export const listUserCommissionsRequest = (id) =>
  apiClient({
    method: 'GET',
    url: `/users/${id}/commissions`,
  });

// Request to get user transaction trace
export const getUserTransactionTraceRequest = (id) =>
  apiClient({
    method: 'GET',
    url: `/users/${id}/transactionTrace`,
  });
