import { Drawer } from "antd";
import React from "react";
import SapaButton from "../../../ui/Button/Button";

const AdministratorsModal = ({ onClose, open }) => {
  return (
    <Drawer
      title="New Admin"
      placement="right"
      onClose={onClose}
      open={open}
      closeIcon={
        <svg
          width="17"
          height="15"
          viewBox="0 0 17 15"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17 7.27429C17 7.65399 16.7178 7.96779 16.3518 8.01745L16.25 8.02429L3.066 8.02369L7.82899 12.7672C8.12251 13.0595 8.12353 13.5344 7.83127 13.8279C7.56558 14.0947 7.14897 14.1198 6.85489 13.9026L6.77061 13.8302L0.720613 7.80617C0.681923 7.76764 0.648317 7.72595 0.619795 7.68192C0.611738 7.66866 0.603539 7.65511 0.595764 7.64127C0.588614 7.62942 0.582151 7.61711 0.576056 7.60466C0.567589 7.58655 0.559317 7.56784 0.551805 7.54875C0.545702 7.53403 0.540607 7.51972 0.535959 7.50529C0.530434 7.48739 0.525064 7.46832 0.520447 7.44897C0.517014 7.43545 0.514294 7.42244 0.511923 7.40938C0.508587 7.38995 0.505749 7.36984 0.503723 7.34949C0.501974 7.33398 0.500921 7.3186 0.50034 7.30321C0.500189 7.29388 0.5 7.28411 0.5 7.27429L0.500376 7.24524C0.50095 7.23052 0.501955 7.21582 0.503393 7.20114L0.5 7.27429C0.5 7.22697 0.504383 7.18066 0.512768 7.13576C0.514711 7.12504 0.517029 7.11403 0.519594 7.10307C0.524921 7.08049 0.531078 7.05875 0.538168 7.03744C0.541647 7.02685 0.545702 7.01553 0.550034 7.0043C0.558798 6.98173 0.568325 6.96022 0.578804 6.93927C0.583673 6.92941 0.589125 6.9191 0.594835 6.90889C0.604208 6.89223 0.613863 6.8764 0.624073 6.86097C0.631277 6.85005 0.639254 6.83868 0.647583 6.82749L0.654074 6.81885C0.67428 6.79245 0.696201 6.76743 0.719669 6.74396L0.72057 6.74327L6.77057 0.718268C7.06407 0.425982 7.53894 0.426965 7.83123 0.720464C8.09694 0.987281 8.12029 1.40399 7.90182 1.69715L7.82903 1.78112L3.068 6.52369L16.25 6.52429C16.6642 6.52429 17 6.86008 17 7.27429Z"
            fill="#2C264B"
          />
        </svg>
      }
    >
      <div className="administrators-modal">
        <div className="administrators-modal-container">
          <div>
            <p>Full Name</p>
            <input type={"text"} placeholder="Kene Ohiaeri" />
          </div>
          <div>
            <p>Email Address</p>
            <input type={"email"} placeholder="keneohiaeri@gmail.com" />
          </div>
        </div>
        <div className="administrators-modal-btn__container">
          <SapaButton
            label={"Send invite"}
            shape="rounded"
            buttonType="primary"
          />
          <SapaButton
            label={"Reset password"}
            shape="rounded"
            buttonType="transparent"
            onClick={onClose}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default AdministratorsModal;
