import { Card, message, Table } from "antd";
import React, { useEffect } from "react";
import { overviewColumn } from "../../DummyAPI/TableData/Columns";
import Filterbox from "../FilterBox/Filterbox";
import SpPagination from "../../components/SpPagination/SpPagination";
import { useDispatch, useSelector } from "react-redux";
import { listUserCommissions } from "../../redux/actions/user";
import { selectUserCommissions, selectUserCommissionsLoading, selectUserCommissionsPagination } from "../../redux/selectors/userSelectors";
import ProgressLoader from "../../components/Loader/ProgressLoader";

const CommissionTable = ({data}) => {
  const userCommissionList = useSelector(selectUserCommissions);
  const isLoading = useSelector(selectUserCommissionsLoading);

  const commissionPagination = useSelector(selectUserCommissionsPagination);

  const { totalRecords = 20, pageSize = 10, currentPage = 1 } = commissionPagination || {};


  const dispatch = useDispatch();

  // adding classname to odd table rows
  const rowClassName = (record, index) => {
    return index % 2 === 1 ? "odd-row" : "";
  };

  useEffect(() => {
    dispatch(listUserCommissions(data?.id)).catch(err => {
      message.error(err);
    });
  }, []);

  
  const tableStyle = {
    tableLayout: "fixed",
  };

  return (
    <ProgressLoader isLoading={isLoading}>
      <Card className="api-overview__table-card">
        <Filterbox 
          showButtons={false} 
          displayFilter={"none"} 
          searchAction={listUserCommissions}
          searchIsLoading={isLoading} 
          searchActionData={{user_id: data?.id}}
        />
        <Table
          dataSource={userCommissionList}
          columns={overviewColumn}
          pagination={false}
          rowClassName={rowClassName}
          style={tableStyle}
        />
      </Card>
      <SpPagination
        defaultCurrent={currentPage}
        totalRecords={totalRecords}
        pageSize={pageSize}
        onChange={(value) => {
          dispatch(listUserCommissions({ nextPage: value, user_id: data?.id })).catch(err => message.error(err));
        }}
      />
    </ProgressLoader>
  );
};

export default CommissionTable;
