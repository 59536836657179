import React from "react";
import { Input, Row } from "antd";
import PropTypes from "prop-types";

const SapaInput = ({
  label,
  placeholder,
  inputStyles,
  onChange,
  containerStyle,
  iconRight,
  bottomLabel,
  value = "",
  inputClass,
  wrapperClass,
  autoFocus,
}) => (
  <div style={containerStyle}>
    {console.log(value)}
    <Row>
      <label>{label}</label>
    </Row>
    <Row className={`input_wrapper ${wrapperClass}`}>
      <Input
        className={`input ${inputClass}`}
        value={value}
        style={inputStyles}
        placeholder={placeholder}
        onChange={onChange}
        suffix={iconRight}
        autoFocus={autoFocus}
      />
      {/* {iconRight && <div className="input__icon_right">{iconRight}</div>} */}
    </Row>
    {!!bottomLabel && bottomLabel}
  </div>
);

SapaInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  handleChange: PropTypes.func,
  containerStyle: PropTypes.object,
  iconRight: PropTypes.object,
  bottomLabel: PropTypes.object,
  autoFocus: PropTypes.bool,
};

SapaInput.defaultProps = {
  autoFocus: false,
};

export default SapaInput;
