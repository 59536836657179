import { Dropdown } from "antd";
import icon from "../../assets/svgs/Info menu.svg";
import { useState, useEffect } from "react";

const SpDropdownButton = ({ customIcon, actionButton, items = [], placement = "bottomRight", trigger = ['click'], onChange }) => {
  const [menuItems, setItems] = useState([]);

  // Set the menu items when the `items` prop changes and add a key to each item
  useEffect(() => {
    const itemsWithKeys = items.map((item, index) => ({
      ...item,
      key: item?.key?? index
    }));

    setItems(itemsWithKeys);
  }, [items]);

  const handleOnClick = (e) => {
    if (typeof onChange === "function") {
      // Extract the key from the event object
      const selectedKey = e?.key + "";
  
      // Find the item from menuItems using the key
      const selectedItem = menuItems.find((item) => {
        return item.key + "" === selectedKey;
      });
  
      // Pass the selected item to the onChange function
      if (selectedItem) {
        onChange(selectedItem);
      }
    }
  };
  

  // Check if there are any valid menu items
  const isMenuEmpty = !menuItems || menuItems.length === 0;

  return (
    <Dropdown
      menu={{
        items: menuItems,  // Ensure valid items with keys
        onClick: handleOnClick,
      }}
      placement={placement}
      trigger={trigger}
      disabled={isMenuEmpty}
    >
      {actionButton? actionButton : <button className="table-action-btn" disabled={isMenuEmpty}>
        {customIcon?? <img src={icon} alt="Dropdown icon" />}
      </button>}
    </Dropdown>
  );
};

export default SpDropdownButton;
