// api/dashboard.js

import apiClient from './apiClient';

// Fetch dashboard statistics
export const fetchDashboardStatisticsRequest = (data) =>
  apiClient({
    method: 'POST',
    url: '/dashboard/statistics',
    data
  });

// Fetch revenue data
export const fetchRevenueDataRequest = () =>
  apiClient({
    method: 'POST',
    url: '/dashboard/revenue',
  });
