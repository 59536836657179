// User selectors
export const selectUsers = (state) => state.user?.users?.data?? [];

export const selectUsersPagination = (state) => ({
    currentPage: state.user.users.currentPage,
    totalRecords: state.user.users.totalRecords,
    pageSize: state.user.users.pageSize,
});
  
export const selectUsersLoading = (state) => state.user?.loading;
export const selectUsersError = (state) => state.user?.users?.error;

// Export selectors
export const selectExportLoading = (state) => state.user?.users?.export.loading;
export const selectExportSuccess = (state) => state.user?.users?.export.success;
export const selectExportError = (state) => state.user?.users?.export.error;

// Block user selectors
export const selectBlockUserSuccess = (state) => state.user?.users?.block.success;
export const selectBlockUserError = (state) => state.user?.users?.block.error;

// Unblock user selectors
export const selectUnblockUserSuccess = (state) => state.user?.users?.unblock.success;
export const selectUnblockUserError = (state) => state.user?.users?.unblock.error;

// Delete user selectors
export const selectDeleteUserSuccess = (state) => state.user?.users?.delete.success;
export const selectDeleteUserError = (state) => state.user?.users?.delete.error;

// Update user selectors
export const selectUpdateUserSuccess = (state) => state.user?.update.success;
export const selectUpdateUserError = (state) => state.user?.update.error;
export const selectUpdateUserLoading = (state) => state.user?.update.loading;

// Get user info selectors
export const selectUserInfo = (state) => state.user?.users?.getUserInfo.data;
export const selectUserInfoLoading = (state) => state.user?.users?.getUserInfo.loading;
export const selectUserInfoError = (state) => state.user?.users?.getUserInfo.error;

// Update wallet balance selectors
export const selectUpdateWalletBalanceSuccess = (state) => state.user?.updateWalletBalance?.success;
export const selectUpdateWalletBalanceError = (state) => state.user?.updateWalletBalance?.error;
export const selectUpdateWalletBalanceLoading = (state) => state.user?.updateWalletBalance?.loading;

// Update user commission selectors
export const selectUpdateUserCommissionSuccess = (state) => state.user?.updateUserCommission.success;
export const selectUpdateUserCommissionError = (state) => state.user?.updateUserCommission.error;

// List user commissions selectors
export const selectUserCommissions = (state) => state.user?.listUserCommissions.data;
export const selectUserCommissionsLoading = (state) => state.user?.listUserCommissions.loading;
export const selectUserCommissionsError = (state) => state.user?.listUserCommissions.error;

export const selectUserCommissionsPagination = (state) => ({
    currentPage: state.user.listUserCommissions.currentPage,
    totalRecords: state.user.listUserCommissions.totalRecords,
    pageSize: state.user.listUserCommissions.pageSize,
});

// Get user transaction trace selectors
export const selectUserTransactionTrace = (state) => state.user?.users?.getUserTransactionTrace.data;
export const selectUserTransactionTraceLoading = (state) => state.user?.users?.getUserTransactionTrace.loading;
export const selectUserTransactionTraceError = (state) => state.user?.users?.getUserTransactionTrace.error;
