import { Select } from "antd";
import SpLabel from "../SpLabel/SpLabel";

const SpSelect = ({ label, htmlFor, id, placeholder, options = [], defaultValue, loading = false, onChange, onSelect, onBlur }) => {
    return (
        <>
            {label && <SpLabel htmlFor={htmlFor}>{label}</SpLabel>}
            <Select
                id={label? htmlFor : id}
                className="sp-select"
                placeholder={placeholder}
                options={options}
                defaultValue={defaultValue}
                loading={loading}
                onChange={onChange}
                onSelect={onSelect}
                onBlur={onBlur}
            />
        </>
    );
}

export default SpSelect;