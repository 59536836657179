import { createReducer } from '@reduxjs/toolkit';
import { fetchDashboardStatistics, fetchRevenueData } from '../actions/dashboard';

const initialState = {
  statistics: null, // Dashboard statistics data
  revenue: null, // Revenue data
  loading: false, // Loading state for any request
  error: null, // Error state for any failed request
};

const dashboardReducer = createReducer(initialState, {
  // Fetch Dashboard Statistics actions
  [fetchDashboardStatistics.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [fetchDashboardStatistics.resolved]: (state, action) => {
    state.loading = false;
    state.statistics = action.payload; // Set statistics data on success
  },
  [fetchDashboardStatistics.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Set error on failure
  },

  // Fetch Revenue Data actions
  [fetchRevenueData.trigger]: (state) => {
    state.loading = true;
    state.error = null;
    state.revenue = null;
  },
  [fetchRevenueData.resolved]: (state, action) => {
    state.loading = false;
    state.revenue = action.payload; // Set revenue data on success
  },
  [fetchRevenueData.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Set error on failure
  },
});

export default dashboardReducer;
