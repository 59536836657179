import React, { useEffect } from 'react';
import nProgress from 'nprogress';
import 'nprogress/nprogress.css';

nProgress.configure({ showSpinner: false });

const ProgressLoader = ({ children, isLoading, noProgressBar = false, style }) => {

  useEffect(() => {
    if (isLoading && !noProgressBar) {
      nProgress.start();
      nProgress.set(0.2)
    } else {
      nProgress.done();
    }
  }, [isLoading]);

  return (
    <>{children}</>
  );
};

export default ProgressLoader;
