import React from 'react'
import SapaHeader from '../../ui/Header/Header'
import { Outlet, useLocation } from 'react-router-dom';


const Users = () => {
  const location = useLocation();

  const paths = location.pathname.split('/');
  const lastSegment = (paths.filter(Boolean).pop())?.toLowerCase();

  return (
    <div className='Users'>
      <SapaHeader page={lastSegment} showArrow={paths?.length > 3} />
      <Outlet />
    </div>
  )
}

export default Users