export const LOCAL_STARAGE_KEYS = {
  SERVICE_INFO: "serviceInfo",
  ACCESS_TOKEN: "auth/accessToken",
  TRANSACTION_PIN: "user/transactionPin",
  SERVICE_INPUT_DETAILS: "service/inputDetails",
  SIGNUP_STEPS: "auth/signupSteps",
};

const STORE_PERSIST_KEY = "persist:root";
export const dataToRemove = [
  ...Object.keys(LOCAL_STARAGE_KEYS),
  STORE_PERSIST_KEY,
];

export const isMobileDevice = () => {
  const regExp = /android|iphone|kindle|ipad/i;
  const isMobileDevice = regExp.test(navigator.userAgent);
  return isMobileDevice;
};
