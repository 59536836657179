export const TransactionTypeItems =[{
    id: 1,
    bill: "Electricty"
},
{
    id: 2,
    bill: "Cable Tv"
},
{
    id: 3,
    bill: "Internet"
},
{
    id: 4,
    bill: "Airtime"
},
{
    id: 5,
    bill: "Exam"
},
{
    id: 6,
    bill: "Transfer"
},
{
    id: 7,
    bill: "Bettings"
}]