//import { Modal } from "antd";
import { call, takeEvery } from "redux-saga/effects";
import { SHOW_MODAL } from "../types";

// const config = {
//   title: "Use Hook!",
//   content: <h4>Hello everyone!!!</h4>,
// };

export function* showModal() {
  yield call(() => console.log("hello there"));
}

export default function* modalSaga() {
  yield takeEvery(SHOW_MODAL, showModal);
}
