import React, { useCallback, useEffect, useState } from "react";
import searchIcon from "../../assets/svgs/Transactions-icons/search.svg";
import filterIcon from "../../assets/svgs/Transactions-icons/filter.svg";
import SapaButton from "../../ui/Button/Button";
import cloud from "../../assets/svgs/Transactions-icons/cloud.svg";
import { Input, Dropdown, Spin, message } from "antd";
import FilterModal from "../../components/FilterGroup/FilterModal";
import addIcon from "../../assets/svgs/addIcon.svg";
import AlertsModal from "../../components/Modal/AlertsModal/AlertsModal";
import AdministratorsModal from "../../components/Modal/AdministratorsModal/AdministratorsModal";
import { useDispatch } from "react-redux";
import useDebounce from './../../hooks/useDebounce';
import { LoadingOutlined } from "@ant-design/icons";
import { exportToCSV, sleep } from "../../utils/utils";

const Filterbox = ({
  displayFilter,
  showButtons,
  type,
  filterType,
  showRefresh,
  showAdmin,
  searchAction,
  searchActionData,
  searchIsLoading = false,
  applyFilterAction,
  applyFilterActionData,
  dataToExport = [],
  exportFileName="data.csv",
  showExportButton = true
}) => {
  const [visible, setVisible] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState(null);
  const [isExporting, setIsExporting] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  function handleVisibleChange(visible) {
    setVisible(visible);
  }

  const handleApplyFilter = (values) => {
    if (typeof applyFilterAction === "function") {
      dispatch(applyFilterAction({...values, ...applyFilterActionData}));
      setVisible(!visible);
    }
  }

  const handleDataExport = useCallback(() => {
    setIsExporting(true);

    let data = null;

    if (typeof dataToExport === "function") {
      data = dataToExport();
    }
    else {
      data = dataToExport;
    }
    
    exportToCSV(data, exportFileName)

    sleep(1000).then(() => {
      setIsExporting(false);
      message.success("Data exported successfully");
    });

  }, [dataToExport, exportFileName]);

  const style = {
    display: displayFilter,
  };

  const menu = (
    <FilterModal
      onClick={handleVisibleChange}
      visible={visible}
      setVisible={setVisible}
      onApplyFilter={handleApplyFilter}
      type={type}
    />
  );

  const debouncedSearchValue = useDebounce(searchValue);

  useEffect(() => {
    
    if (typeof searchAction === 'function' && debouncedSearchValue !== null) {
      dispatch(searchAction({search_term: debouncedSearchValue, ...searchActionData}));
    }
 
  }, [dispatch, debouncedSearchValue]);

  return (
    <div className="Filterbox">
      <div className="sapa-filterbox__header">
        <div className="sapa-filterbox__header_inner">
          <div className="sapa-filterbox__header_container" style={style}>
            <label>Select Filter</label>
            {visible && <div className="overlay" />}
            <Dropdown
              overlay={menu}
              open={visible}
              onOpenChange={handleVisibleChange}
              trigger={["click"]}
            >
              <button className="sapa-filterbox-filter">
                {" "}
                Filtered by: {filterType}
                <span className="filter-icon">
                  <img src={filterIcon} alt="/" />
                </span>{" "}
              </button>
            </Dropdown>
          </div>
          <div className="sapa-filterbox__header_container">
            <label>Search</label>
            <Input
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              prefix={
                <i>
                  <img src={searchIcon} alt="/" />
                </i>
              }
              type={"search"}
              className="sapa-search-input"
              placeholder="Search..."
              suffix={searchIsLoading && <Spin style={{color: "#000080"}} indicator={<LoadingOutlined size={12} />} />}
            />
          </div>
        </div>
        {showButtons && (
          <div className="filter-cloud-btn">
            {showAdmin && (
              <>
                <SapaButton
                  label={"New admin"}
                  customClass="filter-primary_button"
                  shape="rounded"
                  buttonType="primary"
                  icon={addIcon}
                  displayIcon={true}
                  onClick={showDrawer}
                />
                <AdministratorsModal open={open} onClose={onClose} />
              </>
            )}
            {showRefresh && (
              <>
                <SapaButton
                  label={"New alert"}
                  customClass="filter-primary_button"
                  shape="rounded"
                  buttonType="primary"
                  icon={addIcon}
                  displayIcon={true}
                  onClick={showDrawer}
                />
                <AlertsModal open={open} onClose={onClose} />
              </>
            )}

            {showRefresh || showAdmin ? (
              ""
            ) : (
              showExportButton && <SapaButton
              label={"Export CSV"}
              customClass="sapa-filterbox__header_button"
              buttonType="transparent"
              icon={cloud}
              displayIcon={true}
              loading={isExporting}
              onClick={handleDataExport}
            />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

Filterbox.defaultProps = {
  showButtons: true,
  filterType: "transaction type",
  showRefresh: false,
  showAdmin: false,
};

export default Filterbox;
