import { createReducer } from '@reduxjs/toolkit';
import {
  listIncomingRequests,
  searchIncomingRequests,
  filterIncomingRequests,
  exportIncomingRequests,
  getIncomingRequestInfo,
  updateIncomingRequestInfo,
  requeryIncomingRequest,
  addIncomingRequest,
} from '../actions/incomingRequest';

const initialState = {
  incomingRequests: {
    data: [],            // List of incoming requests (includes filtered or search results)
    currentPage: 1,      // Current page for pagination
    totalRecords: 0,     // Total number of records
    pageSize: 10,        // Items per page
  },
  loading: false,        // Loading state for listing, filtering, searching, and exporting incoming requests
  error: null,           // General error state

  export: {
    loading: false,      // Loading state for export action
    success: false,      // Tracks if export was successful
    error: null,         // Export-specific error
  },

  viewDetails: {
    data: null,
    error: null,
  },
  updateInfo: {
    success: false,
    error: null,
  },
  requery: {
    success: false,
    error: null,
    loading: false
  },
  addIncomingRequest: {
    success: false,
    error: null,
  },
};

const incomingRequestsReducer = createReducer(initialState, {
  [listIncomingRequests.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [listIncomingRequests.resolved]: (state, action) => {
    const { 
      data: incomingRequests, 
      current_page: currentPage, 
      next_page_url: nextPageUrl, 
      prev_page_url: prevPageUrl, 
      total: totalRecords, 
      per_page: pageSize 
    } = action.payload;

    state.loading = false;
    state.incomingRequests = {
      data: incomingRequests,
      currentPage,
      totalRecords, 
      pageSize,  
      nextPageUrl,
      prevPageUrl   
    };
  },
  [listIncomingRequests.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  // Filter incoming requests
  [filterIncomingRequests.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [filterIncomingRequests.resolved]: (state, action) => {
    const { incomingRequests, currentPage, totalRecords, pageSize } = action.payload;
    state.loading = false;
    state.incomingRequests = {
      data: incomingRequests,
      currentPage,
      totalRecords,
      pageSize,
    };
  },
  [filterIncomingRequests.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  // Search incoming requests
  [searchIncomingRequests.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [searchIncomingRequests.resolved]: (state, action) => {
    const { incomingRequests, currentPage, totalRecords, pageSize } = action.payload;
    state.loading = false;
    state.incomingRequests = {
      data: incomingRequests,
      currentPage,
      totalRecords,
      pageSize,
    };
  },
  [searchIncomingRequests.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload;
  },

  // Export incoming requests
  [exportIncomingRequests.trigger]: (state) => {
    state.export.loading = true;
    state.export.success = false;
    state.export.error = null;
  },
  [exportIncomingRequests.resolved]: (state) => {
    state.export.loading = false;
    state.export.success = true;
  },
  [exportIncomingRequests.rejected]: (state, action) => {
    state.export.loading = false;
    state.export.success = false;
    state.export.error = action.payload;
  },

  // View incoming request details
  [getIncomingRequestInfo.trigger]: (state) => {
    state.viewDetails.error = null;
  },
  [getIncomingRequestInfo.resolved]: (state, action) => {
    state.viewDetails.data = action.payload;
  },
  [getIncomingRequestInfo.rejected]: (state, action) => {
    state.viewDetails.error = action.payload;
  },

  // Update incoming request info
  [updateIncomingRequestInfo.trigger]: (state) => {
    state.updateInfo.error = null;
  },
  [updateIncomingRequestInfo.resolved]: (state) => {
    state.updateInfo.success = true;
  },
  [updateIncomingRequestInfo.rejected]: (state, action) => {
    state.updateInfo.success = false;
    state.updateInfo.error = action.payload;
  },

  // Update incoming request balance
  [requeryIncomingRequest.trigger]: (state) => {
    state.requery.error = null;
    state.requery.loading = true;
  },
  [requeryIncomingRequest.resolved]: (state) => {
    state.requery.success = true;
    state.requery.loading = false;
  },
  [requeryIncomingRequest.rejected]: (state, action) => {
    state.requery.success = false;
    state.requery.error = action.payload;
    state.requery.loading = false;
  },

  // Add incoming request
  [addIncomingRequest.trigger]: (state) => {
    state.addIncomingRequest.error = null;
  },
  [addIncomingRequest.resolved]: (state) => {
    state.addIncomingRequest.success = true;
  },
  [addIncomingRequest.rejected]: (state, action) => {
    state.addIncomingRequest.success = false;
    state.addIncomingRequest.error = action.payload;
  },
});

export default incomingRequestsReducer;
