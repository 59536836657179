import { Menu } from "antd";
import PropTypes from "prop-types";

const SpMenu = ({ className, items, renderItem, onSelect }) => {
    const handleSelect = ({ selectedKeys }) => {
        if (typeof onSelect === "function") {
            onSelect({ item: items[selectedKeys[0]], index: selectedKeys[0] });
        }
    };

    return (
        <Menu className={className} selectable onSelect={handleSelect}>
            {items.map((list, index) => (
                <Menu.Item key={index}>
                    {renderItem(list, index)}
                </Menu.Item>
            ))}
        </Menu>
    );
};

SpMenu.propTypes = {
    className: PropTypes.string,
    items: PropTypes.array,
    renderItem: PropTypes.func.isRequired
};

SpMenu.defaultProps = {
    className: "filter-transaction-items",
    items: []
};

export default SpMenu;
