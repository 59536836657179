// sagas/providerSaga.js

import { call, all, takeEvery } from 'redux-saga/effects';
import {
  listProvidersRequest,
  searchProvidersRequest,
  filterProvidersRequest,
  exportProvidersRequest,
  getProviderInfoRequest,
  updateProviderInfoRequest,
  updateProviderBalanceRequest,
  addProviderRequest
} from './../../api/provider'; // API imports
import {
  listProviders,
  searchProviders,
  filterProviders,
  exportProviders,
  getProviderInfo,
  updateProviderInfo,
  updateProviderBalance,
  addProvider
} from '../actions/provider'; // Promise actions

import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

import { translateErrorResponse } from '../../utils/utils';

// Saga to handle listing providers
export function* handleListProviders(action) {
  try {
    const response = yield call(listProvidersRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle searching providers
export function* handleSearchProviders(action) {
  try {
    const response = yield call(searchProvidersRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle filtering providers
export function* handleFilterProviders(action) {
  try {
    const response = yield call(filterProvidersRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle exporting providers
export function* handleExportProviders(action) {
  try {
    const response = yield call(exportProvidersRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle viewing provider details
export function* handleGetProviderInfo(action) {
  try {
    const response = yield call(getProviderInfoRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle updating provider info
export function* handleUpdateProviderInfo(action) {
  try {
    const response = yield call(updateProviderInfoRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle updating provider balance
export function* handleUpdateProviderBalance(action) {
  try {
    const response = yield call(updateProviderBalanceRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle adding a provider
export function* handleAddProvider(action) {
  try {
    const response = yield call(addProviderRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for provider actions
export default function* providerSaga() {
  yield all([
    takeEvery(listProviders.trigger, handleListProviders), // Watch for listProviders action
    takeEvery(searchProviders.trigger, handleSearchProviders), // Watch for searchProviders action
    takeEvery(filterProviders.trigger, handleFilterProviders), // Watch for filterProviders action
    takeEvery(exportProviders.trigger, handleExportProviders), // Watch for exportProviders action
    takeEvery(getProviderInfo.trigger, handleGetProviderInfo), // Watch for getProviderInfo action
    takeEvery(updateProviderInfo.trigger, handleUpdateProviderInfo), // Watch for updateProviderInfo action
    takeEvery(updateProviderBalance.trigger, handleUpdateProviderBalance), // Watch for updateProviderBalance action
    takeEvery(addProvider.trigger, handleAddProvider), // Watch for addProvider action
  ]);
}
