import { Drawer } from "antd";
import React from "react";
import SapaButton from "../../../ui/Button/Button";
import CloseIcon from "../../Svgs/CloseIcon";

const SpDrawer = ({ onClose, open, title, children, onSaveChanges, saveButtonLoading }) => {

  return (
    <Drawer
      title={title}
      placement="right"
      onClose={onClose}
      open={open}
      closeIcon={
        <CloseIcon />
      }
    >
      <div className="services-modal">
        <div className="services-modal-container">
          {children}
        </div>
        <div className="services-modal-btn__container">
          <SapaButton
            label={"Save Changes"}
            shape="rounded"
            buttonType="primary"
            onClick={onSaveChanges}
            loading={saveButtonLoading}
          />
          <SapaButton
            label={"Cancel"}
            shape="rounded"
            buttonType="transparent"
            onClick={onClose}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default SpDrawer;
