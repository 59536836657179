// sagas/transactionSaga.js

import { call, all, takeEvery } from 'redux-saga/effects';
import {
  filterTransactionsRequest,
  searchTransactionsRequest,
  exportTransactionsRequest,
  listTransactionsRequest,
  getTransactionDetailsRequest
} from './../../api/transaction'; // API imports

import {
  filterTransactions,
  searchTransactions,
  exportTransactions,
  listTransactions,
  getTransactionDetails
} from '../actions/transaction'; // Promise actions

import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

import { translateErrorResponse } from '../../utils/utils';

// Saga to handle filtering transactions
export function* handleFilterTransactions(action) {
  try {
    const response = yield call(filterTransactionsRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ??  {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle searching transactions
export function* handleSearchTransactions(action) {
  try {
    const response = yield call(searchTransactionsRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ??  {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle exporting transactions
export function* handleExportTransactions(action) {
  try {
    const response = yield call(exportTransactionsRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ??  {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle listing transactions
export function* handleListTransactions(action) {
  try {
    const response = yield call(listTransactionsRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle getting transaction details
export function* handleGetTransactionDetails(action) {
  try {
    const response = yield call(getTransactionDetailsRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ??  {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for transaction actions
export default function* transactionSaga() {
  yield all([
    takeEvery(filterTransactions.trigger, handleFilterTransactions), // Watch for filterTransactions action
    takeEvery(searchTransactions.trigger, handleSearchTransactions), // Watch for searchTransactions action
    takeEvery(exportTransactions.trigger, handleExportTransactions), // Watch for exportTransactions action
    takeEvery(listTransactions.trigger, handleListTransactions), // Watch for listTransactions action
    takeEvery(getTransactionDetails.trigger, handleGetTransactionDetails) // Watch for getTransactionDetails action
  ]);
}
