import React, { useState } from "react";
import arrow from "../../assets/svgs/header-icon/arrow-down.svg";
import arrowGrey from "../../assets/svgs/header-icon/arrow-down-grey.svg";
import SapaButton from "../../ui/Button/Button";
import cloud from "../../assets/svgs/Transactions-icons/cloud.svg";
import refresh from "../../assets/svgs/Refresh-1.svg";

const QueryLogs = () => {
  const [selected, setSelected] = useState(false);
  return (
    <div className="Query-logs">
      <div className="query-logs__filter-box">
        <div className="query-logs__duration">
          <p>Duration</p>
          {/* <Dropdown trigger={["click"]}> */}
          <button
            onClick={() => setSelected(!selected)}
            className={
              selected ? "selected filter-modal-btn" : "filter-modal-btn"
            }
          >
            {" "}
            Today
            {selected ? (
              <img src={arrow} alt="/" />
            ) : (
              <img src={arrowGrey} alt="/" />
            )}
          </button>
          {/* </Dropdown> */}
        </div>
        <div className="query-logs__btn-container">
          <SapaButton
            label={"Refresh"}
            shape="rounded"
            buttonType="primary"
            icon={refresh}
            displayIcon={true}
          />
          <SapaButton
            label={"Download"}
            shape="rounded"
            buttonType="transparent"
            icon={cloud}
            displayIcon={true}
          />
        </div>
      </div>
      <div className="queries">
        <span>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit,
          <br /> sed do eiusmod tempor incididunt ut labore et dolore magna
          aliqua.
          <br /> Ut enim ad minim veniam, quis nostrud exercitation ullamco
          laboris
          <br /> nisi ut aliquip ex ea commodo consequat. Lorem ipsum dolor sit
          amet,
          <br />
          consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
          <br />
          labore et dolore magna aliqua. Ut enim ad minim veniam, quis
          <br />
          nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo
          consequat.
        </span>
      </div>
    </div>
  );
};

export default QueryLogs;
