import { createReducer } from '@reduxjs/toolkit';
import {
  listProviders,
  searchProviders,
  filterProviders,
  exportProviders,
  getProviderInfo,
  updateProviderInfo,
  updateProviderBalance,
  addProvider,
} from '../actions/provider';

const initialState = {
  providers: {
    data: [],            // List of providers (includes filtered or search results)
    currentPage: 1,      // Current page for pagination
    totalRecords: 0,       // Total number of pages
    pageSize: 10,        // Items per page
  },
  loading: false,        // Loading state for listing, filtering, searching, and exporting providers
  error: null,           // General error state

  // Export state as an object
  export: {
    loading: false,      // Loading state for export action
    success: false,      // Tracks if export was successful
    error: null,         // Export-specific error
  },

  // States for provider actions
  viewDetails: {
    data: null,
    error: null,
  },
  updateInfo: {
    success: false,
    error: null,
  },
  updateBalance: {
    success: false,
    error: null,
    loading: false
  },
  addProvider: {
    success: false,
    error: null,
  },
};

const providersReducer = createReducer(initialState, {
  // Listing providers (with pagination)
  [listProviders.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [listProviders.resolved]: (state, action) => {
  
    const { 
      data: providers, 
      current_page: currentPage, 
      next_page_url: nextPageUrl, 
      prev_page_url: prevPageUrl, 
      total: totalRecords, 
      per_page: pageSize 
    } = action.payload;


    state.loading = false;
    state.providers = {
      data: providers,
      currentPage,
      totalRecords, 
      pageSize,  
      nextPageUrl,
      prevPageUrl   
    };
    
  },
  [listProviders.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle listing error
  },

  // Filter providers
  [filterProviders.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [filterProviders.resolved]: (state, action) => {
    const { providers, currentPage, totalRecords, pageSize } = action.payload;
    state.loading = false;
    state.providers = {
      data: providers,    // Set filtered providers data
      currentPage,        // Update pagination after filtering
      totalRecords,
      pageSize,
    };
  },
  [filterProviders.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle filtering error
  },

  // Search providers
  [searchProviders.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [searchProviders.resolved]: (state, action) => {
    const { providers, currentPage, totalRecords, pageSize } = action.payload;
    state.loading = false;
    state.providers = {
      data: providers,    // Set search results
      currentPage,        // Update pagination after search
      totalRecords,
      pageSize,
    };
  },
  [searchProviders.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle search error
  },

  // Export providers
  [exportProviders.trigger]: (state) => {
    state.export.loading = true;  // Set export-specific loading
    state.export.success = false; // Reset success status
    state.export.error = null;    // Clear export errors
  },
  [exportProviders.resolved]: (state) => {
    state.export.loading = false; // Stop export loading
    state.export.success = true;  // Set export success flag
  },
  [exportProviders.rejected]: (state, action) => {
    state.export.loading = false; // Stop export loading
    state.export.success = false; // Mark export as unsuccessful
    state.export.error = action.payload; // Handle export error
  },

  // View provider details
  [getProviderInfo.trigger]: (state) => {
    state.viewDetails.error = null;
  },
  [getProviderInfo.resolved]: (state, action) => {
    state.viewDetails.data = action.payload; // Set provider details
  },
  [getProviderInfo.rejected]: (state, action) => {
    state.viewDetails.error = action.payload; // Handle view details error
  },

  // Update provider info
  [updateProviderInfo.trigger]: (state) => {
    state.updateInfo.error = null;
  },
  [updateProviderInfo.resolved]: (state) => {
    state.updateInfo.success = true; // Set success flag for update
  },
  [updateProviderInfo.rejected]: (state, action) => {
    state.updateInfo.success = false; // Mark update as unsuccessful
    state.updateInfo.error = action.payload; // Handle update error
  },

  // Update provider balance
  [updateProviderBalance.trigger]: (state) => {
    state.updateBalance.error = null;
    state.updateBalance.loading = true;
  },
  [updateProviderBalance.resolved]: (state) => {
    state.updateBalance.success = true; // Set success flag for balance update
    state.updateBalance.loading = false;
  },
  [updateProviderBalance.rejected]: (state, action) => {
    state.updateBalance.success = false; // Mark balance update as unsuccessful
    state.updateBalance.error = action.payload; // Handle balance update error
    state.updateBalance.loading = false;
  },

  // Add provider
  [addProvider.trigger]: (state) => {
    state.addProvider.error = null;
  },
  [addProvider.resolved]: (state) => {
    state.addProvider.success = true; // Set success flag for adding provider
  },
  [addProvider.rejected]: (state, action) => {
    state.addProvider.success = false; // Mark add provider as unsuccessful
    state.addProvider.error = action.payload; // Handle add provider error
  },
});

export default providersReducer;
