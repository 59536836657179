import React, { useEffect } from "react";
import { Card, Table } from "antd";
import Filterbox from "../../ui/FilterBox/Filterbox";
import { serviceCategoriesColumn, servicesColumn } from "../../DummyAPI/TableData/Columns";
import { useDispatch, useSelector } from "react-redux";
import { listServices } from './../../redux/actions/service';
import ProgressLoader from "../../components/Loader/ProgressLoader";
import { selectServicesData, selectServicesLoading, selectServicesPagination } from './../../redux/selectors/serviceSelectors';
import { formatDate } from "../../utils/utils";
import SpPagination from "../../components/SpPagination/SpPagination";

const AllServices = ({ type }) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(selectServicesLoading)
  const serviceList = useSelector(selectServicesData);

  const servicesPagination = useSelector(selectServicesPagination);
    const { totalRecords = 20, pageSize = 10, currentPage = 1 } = servicesPagination || {};

  const tableStyle = {
    tableLayout: "fixed",
  };


  const rowClassName = (record, index) => {
    return index % 2 === 1 ? "odd-row" : "";
  };

  useEffect(() => {
    dispatch(listServices({ type }));
  }, [dispatch, type]);


  const prepareDataToExport = () => {
    let data = serviceList.map(item => {
      return {
        "Service Name": item?.name,
        "Description": item?.description,
        "Service Code": item?.code,
        "Transaction Fee": "NGN" + item?.transaction_fee,
        "Sort Code": item?.sort_code,
        "Current Provider": item?.active_provider?.name,
        "Providers": item?.providers?.length ?? "N?A",
        "Status": item?.status,
        "Date": formatDate(item?.created_at)
      };
    });

    return data;
  };

  return (
    <ProgressLoader isLoading={isLoading}>
      <Card className="sapa-services__container">
        <Filterbox
          displayFilter={"none"}
          //filterType="Service"
          searchIsLoading={isLoading}
          searchAction={listServices}
          searchActionData={{
            type
          }}
          /* applyFilterAction={listServices}
          applyFilterActionData={{
            type
          }}
          exportFileName="services.csv"
          dataToExport={prepareDataToExport} */
          showExportButton={false}
        />

        <Table
          dataSource={serviceList}
          columns={type?.toLowerCase() === "services" ? serviceCategoriesColumn : servicesColumn}
          pagination={false}
          rowClassName={rowClassName}
          style={tableStyle}
        />

        <SpPagination
          defaultCurrent={currentPage}
          totalRecords={totalRecords}
          pageSize={pageSize}
          onChange={(value) => {
            dispatch(listServices({ nextPage: value }));
          }}
        />
      </Card>
    </ProgressLoader>
  );
};

export default AllServices;
