// sagas/serviceSaga.js

import { call, all, takeEvery } from 'redux-saga/effects';
import {
  listServicesRequest,
  filterServicesRequest,
  searchServicesRequest,
  exportServicesRequest,
  switchProviderRequest
} from './../../api/service'; // API imports
import {
  listServices,
  filterServices,
  searchServices,
  exportServices,
  switchProvider
} from '../actions/service'; // Promise actions

import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';

import { translateErrorResponse } from '../../utils/utils';

// Saga to handle listing services
export function* handleListServices(action) {
  try {
    const response = yield call(listServicesRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle filtering services
export function* handleFilterServices(action) {
  try {
    const response = yield call(filterServicesRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle searching services
export function* handleSearchServices(action) {
  try {
    const response = yield call(searchServicesRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle exporting services
export function* handleExportServices(action) {
  try {
    const response = yield call(exportServicesRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle switch provider
export function* handleSwitchProvider(action) {
  try {
    const response = yield call(switchProviderRequest, action.payload);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action
  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for service actions
export default function* serviceSaga() {
  yield all([
    takeEvery(listServices.trigger, handleListServices), // Watch for listServices action
    takeEvery(filterServices.trigger, handleFilterServices), // Watch for filterServices action
    takeEvery(searchServices.trigger, handleSearchServices), // Watch for searchServices action
    takeEvery(exportServices.trigger, handleExportServices), // Watch for exportServices action
    takeEvery(switchProvider.trigger, handleSwitchProvider), // Watch for switchProvider action
  ]);
}
