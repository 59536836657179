// api/auth.js

import apiClient from './apiClient';

// Request to login
export const loginRequest = (data) =>
  apiClient({
    method: 'POST',
    url: '/auth/login',
    data,
  });

// Request to refresh token
export const refreshTokenRequest = () =>
  apiClient({
    method: 'POST',
    url: '/auth/refreshToken',
  });

// Request to register
export const registerRequest = (data) =>
  apiClient({
    method: 'POST',
    url: '/auth/register',
    data,
  });

// Request to logout
export const logoutRequest = () =>
  apiClient({
    method: 'POST',
    url: '/auth/logout',
  });
