export const paymentMethods = [
  {
    id: 1,
    method: "Debit Card",
  },
  {
    id: 2,
    method: "Sapa Wallet",
  },
  {
    id: 3,
    method: "On Credit",
  },
];

export const API_Providers = [
  {
    id: 1,
    provider: "Airvend",
  },
  {
    id: 2,
    provider: "Shago",
  },
  {
    id: 3,
    provider: "Buypower",
  },
];

export const dateAdded = [
  {
    id: 1,
    date: "All",
  },
  {
    id: 2,
    date: "Last Week",
  },
  {
    id: 3,
    date: "Last Month",
  },
  {
    id: 1,
    date: "Last 6 Months",
  },
  {
    id: 1,
    date: "Last Year",
  },
];

export const userStatus = [
  {
    id: 1,
    status: "All",
  },
  {
    id: 2,
    status: "Active",
  },
  {
    id: 3,
    status: "Blocked",
  },
];

export const KYC_Tier = [
  {
    id: 1,
    method: "Tier 1",
  },
  {
    id: 2,
    method: "Tier 2",
  },
  {
    id: 3,
    method: "Tier 3",
  },
];
