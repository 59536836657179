import { createReducer } from '@reduxjs/toolkit';
import { login, logout, refreshToken } from '../actions/auth';

const initialState = {
  loading: false,
  error: null,
  data: null, // User data (e.g., tokens, user details) on successful login
};

const authReducer = createReducer(initialState, {
  // Login actions
  [login.trigger]: (state) => {
    state.loading = true;
    state.error = null;
    state.data = null;
  },
  [login.resolved]: (state, action) => {
    state.loading = false;
    state.data = action.payload; // Set data to the payload from the successful login response
  },
  [login.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Set error to the payload from the failed login response
  },

  // Logout actions
  [logout.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [logout.resolved]: (state) => {
    state.loading = false;
    state.data = null; // Clear user data on logout
  },
  [logout.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Set error if logout fails
  },

  // Refresh Token actions
  [refreshToken.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [refreshToken.resolved]: (state, action) => {
    state.loading = false;
    state.data = {
      ...state.data,
      token: action.payload.token, // Update token in user data
    };
  },
  [refreshToken.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Set error if refresh token fails
  },
});

export default authReducer;
