const apiproviderIcon = ({ fill = "#2C264B" }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.4">
<path fillRule="evenodd" clipRule="evenodd" d="M4.40833 7.52962C4.56667 7.44629 4.71667 7.37962 4.88333 7.32129C5.15 7.22962 5.425 7.16296 5.71667 7.14629H5.725C5.8 7.13796 5.88333 7.13796 5.95833 7.13796H14.0417C14.1167 7.13796 14.2 7.13796 14.275 7.14629H14.2833C14.5667 7.17129 14.85 7.22962 15.1083 7.32962H15.1167C15.2833 7.37962 15.4417 7.44629 15.5917 7.52962C16.3667 7.02129 16.875 6.14629 16.875 5.15462C16.875 3.59629 15.6 2.32129 14.0417 2.32129H5.95833C4.4 2.32129 3.125 3.59629 3.125 5.15462C3.125 6.14629 3.63333 7.02129 4.40833 7.52962Z" fill={fill}/>
<path fillRule="evenodd" clipRule="evenodd" d="M15.4917 8.45452C15.0667 8.19619 14.575 8.05452 14.0417 8.05452H5.95833C5.425 8.05452 4.93333 8.19619 4.50833 8.45452C3.68333 8.94616 3.125 9.85449 3.125 10.8878C3.125 11.8212 3.58333 12.6545 4.29167 13.1712C4.75833 13.5212 5.33333 13.7212 5.95833 13.7212H9.41667H10.6667H14.0417C14.6667 13.7212 15.2417 13.5212 15.7083 13.1712C16.4167 12.6545 16.875 11.8212 16.875 10.8878C16.875 9.85449 16.3167 8.94616 15.4917 8.45452Z" fill={fill}/>
</g>
<path fillRule="evenodd" clipRule="evenodd" d="M6.375 4.45427H7.625C7.96667 4.45427 8.25 4.72927 8.25 5.07927C8.25 5.42094 7.96667 5.70427 7.625 5.70427H6.375C6.03333 5.70427 5.75 5.42094 5.75 5.07927C5.75 4.72927 6.03333 4.45427 6.375 4.45427Z" fill={fill}/>
<path fillRule="evenodd" clipRule="evenodd" d="M6.375 11.5125H7.625C7.96667 11.5125 8.25 11.2292 8.25 10.8875C8.25 10.5458 7.96667 10.2625 7.625 10.2625H6.375C6.03333 10.2625 5.75 10.5458 5.75 10.8875C5.75 11.2292 6.03333 11.5125 6.375 11.5125Z" fill={fill}/>
<path fillRule="evenodd" clipRule="evenodd" d="M9.41668 13.721V14.8627C8.82502 15.0543 8.37502 15.5043 8.18333 16.096H4.875C4.53333 16.096 4.25 16.3793 4.25 16.721C4.25 17.0627 4.53333 17.346 4.875 17.346H8.18333C8.43335 18.1293 9.15835 18.6793 10.0417 18.6793C10.925 18.6793 11.65 18.1293 11.9 17.346H15.2084C15.55 17.346 15.8334 17.0627 15.8334 16.721C15.8334 16.3793 15.55 16.096 15.2084 16.096H11.9C11.7083 15.5043 11.2584 15.0543 10.6667 14.8627V13.721H9.41668ZM10.0417 17.4293C10.4417 17.4293 10.75 17.121 10.75 16.721C10.75 16.321 10.4417 16.0127 10.0417 16.0127C9.64168 16.0127 9.33335 16.321 9.33335 16.721C9.33335 17.121 9.64168 17.4293 10.0417 17.4293Z" fill={fill}/>
</svg>
);

export default apiproviderIcon;
