import React, { cloneElement, useState } from "react";
import SpDropdownButton from "../SpDropdownButton/SpDropdownButton";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { message } from "antd";

const ActionHandler = ({ 
    isDropdown = false, 
    items, 
    actionButton, 
    actionButtonComponent, 
    redirectData,
    redirectTo, 
    dispatchAction,
    dispatchActionData,
    customIcon,
    callback,
}) => {
    const [component, setComponent] = useState(null);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleActionEvent = (event) => {
        const { component: eventComponent, redirectTo: eventRedirectTo, data: eventRedirectData = {}, dispatchAction: eventDispatchAction } = event || {};

        if (eventComponent) {
            setComponent(eventComponent);
            setOpen(true);
            return;
        }

        if (eventRedirectTo) {
            navigate(eventRedirectTo, {state: eventRedirectData});
            return;
        }

        if (eventDispatchAction) {
            dispatch(eventDispatchAction).then(res => {
                message.success(res?.message?? "Requeried successful.");
            }).catch(err => {
                message.error(err?.message?? err);
            });
            return;
        }

        if (actionButtonComponent) {
            setComponent(actionButtonComponent);
            setOpen(true);
            return;
        }

        if (redirectTo) {
            navigate(redirectTo, {state: redirectData});
            return;
        }

        if (typeof dispatchAction === "object") {
            dispatch(dispatchAction).then(res => {
                message.success(res?.message?? "Request successful.");
            }).catch(err => {
                message.error(err?.message?? err);
            });
           
            return;
        }

        if (typeof dispatchAction === "function") {
            //dispatch(dispatchAction({...event, ...dispatchActionData}));
            dispatch(dispatchAction({...event, ...dispatchActionData})).then(res => {
                message.success(res?.message?? "Request successful.");
            }).catch(err => {
                message.error(err?.message?? err);
            });
            return;
        }

        if (typeof callback === "function") {
            callback(event);
        }

    };

    // Clone the actionButton if it exists
    const clonedActionButton = !isDropdown && actionButton 
        ? cloneElement(actionButton, { onClick: handleActionEvent }) 
        : null;

    return (
        <>
            {component && cloneElement(component, { open, onClose: () => setOpen(false) })}
            {isDropdown ? (
                <SpDropdownButton
                    items={items}
                    actionButton={clonedActionButton}
                    onChange={handleActionEvent}
                    customIcon={customIcon}
                />
            ) : (
                clonedActionButton || <></> // Render actionButton or an empty fragment
            )}
        </>
    );
};

export default ActionHandler;
