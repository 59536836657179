import { useLocation } from "react-router-dom";
import Transactions from "../../Transactions/Main";


const UserTransactions = () => {
    const location = useLocation();

    return (
        <div style={{marginTop: -24}}>
            <Transactions showHeader={false} type={"apiType"} data={location.state} />
        </div>
    );
}

export default UserTransactions;