import React from "react";
import { Line } from "react-chartjs-2";

import {
  Chart as ChartJS,
  CategoryScale, // For category scale (x-axis)
  LinearScale,   // For linear scale (y-axis)
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

// Register the necessary components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard_LineChart = ({ chartData }) => {
  // Check if chartData exists and has datasets
  const hasData = chartData?.datasets?.length > 0 && chartData.datasets[0].data.length > 0;
  

  const options = {
    maintainAspectRatio: false,
    tension: 0.4,
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "black",
          // Show x-axis ticks only if data exists
          display: hasData,
        },
      },
      y: {
        beginAtZero: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          callback: function (label) {
            // Use the actual chart data for y-axis labels
            if (!hasData) return null;
            return `₦${label}k`;
          },
          color: "black",
          // Show y-axis ticks only if data exists
          display: hasData,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div style={{paddingTop: 8, paddingBottom: 8}}>
      {/* Conditionally render the chart based on data presence */}
      {hasData ? (
        <Line options={options} data={chartData} height="330px" />
      ) : (
        <div style={{
          minHeight: "40vh", 
          display: "flex", 
          justifyContent: "center", 
          alignItems: "center",
          marginTop: "-8vh"
        }}>No data available</div>
      )}
    </div>
  );
};

export default Dashboard_LineChart;
