import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SpTab from "../../components/SpTab/SpTab";
import { Card } from "antd";

const UserTabs = () => {
  const [activeTab, setActiveTab] = useState(0);
  const navigate = useNavigate();
  const buttonLabels = ["Overview", "Commissions", "Transactions"];

  const location = useLocation();

  // Split the pathname to get the last segment
  const paths = location.pathname.split('/');
  const lastSegment = (paths.filter(Boolean).pop())?.toLowerCase();

  useEffect(() => {
    // Find the index of the matching tab
    const index = buttonLabels.findIndex(
      (item) => item.toLowerCase() === lastSegment
    );

    // If found, set the active tab, else set it to 0 (default)
    if (index !== -1) {
      setActiveTab(index);
    } else {
      setActiveTab(0); // Default to the first tab if no match
    }
  }, [lastSegment, buttonLabels]);

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <SpTab
          items={buttonLabels}
          activeIndex={activeTab}
          onChange={({ index }) => {
            // Navigate to the selected tab
            navigate(`/dashboard/users/${buttonLabels[index]?.toLowerCase()}`, {state: location.state});
          }}
        />
      </div>
      <div className="Users">
        <Card className="sapa-users__container">
          <Outlet />
        </Card>
      </div>
    </>
  );
};

export default UserTabs;
