import { createReducer } from '@reduxjs/toolkit';
import {
  filterTransactions,
  searchTransactions,
  exportTransactions,
  listTransactions,
  getTransactionDetails,
} from '../actions/transaction';

const initialState = {
  transactions: {
    data: [],            // List of transactions (includes filtered or search results)
    currentPage: 1,      // Current page for pagination
    totalRecords: 0,       // Total number of pages
    pageSize: 10,        // Items per page
  },
  loading: false,        // Loading state for listing, filtering, or searching transactions
  error: null,           // General error state

  // Export state as an object
  export: {
    loading: false,      // Loading state for export action
    success: false,      // Tracks if export was successful
    error: null,         // Export-specific error
  },

  // State for getting transaction details
  getTransactionDetails: {
    data: null,          // Transaction details data
    loading: false,
    error: null,
  },
};

const transactionsReducer = createReducer(initialState, {
  // Filter transactions
  [filterTransactions.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [filterTransactions.resolved]: (state, action) => {
    const { transactions, currentPage, totalRecords, pageSize } = action.payload;
    state.loading = false;
    state.transactions = {
      data: transactions,    // Set filtered transactions data
      currentPage,           // Update current page from the backend
      totalRecords,            // Update total pages from the backend
      pageSize,              // Update page size (if provided by backend)
    };
  },
  [filterTransactions.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle filtering error
  },

  // Search transactions
  [searchTransactions.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [searchTransactions.resolved]: (state, action) => {
    const { transactions, currentPage, totalRecords, pageSize } = action.payload;
    state.loading = false;
    state.transactions = {
      data: transactions,    // Set search results
      currentPage,           // Update pagination after search
      totalRecords,
      pageSize,
    };
  },
  [searchTransactions.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle search error
  },

  // Export transactions
  [exportTransactions.trigger]: (state) => {
    state.export.loading = true;  // Set export-specific loading
    state.export.success = false; // Reset success status
    state.export.error = null;    // Clear export errors
  },
  [exportTransactions.resolved]: (state) => {
    state.export.loading = false; // Stop export loading
    state.export.success = true;  // Set export success flag
  },
  [exportTransactions.rejected]: (state, action) => {
    state.export.loading = false; // Stop export loading
    state.export.success = false; // Mark export as unsuccessful
    state.export.error = action.payload; // Handle export error
  },

  // List transactions
  [listTransactions.trigger]: (state) => {
    state.loading = true;
    state.error = null;
  },
  [listTransactions.resolved]: (state, action) => {

    const { 
      data: transactions, 
      current_page: currentPage, 
      next_page_url: nextPageUrl, 
      prev_page_url: prevPageUrl, 
      total: totalRecords, 
      per_page: pageSize 
    } = action.payload;

    //const { data: transactions, currentPage, totalRecords, pageSize } = action.payload;

    state.loading = false;
    state.transactions = {
      data: transactions,    // Set list of transactions
      currentPage,           // Update pagination after listing
      totalRecords,
      pageSize,
      nextPageUrl,
      prevPageUrl
    };
  },
  [listTransactions.rejected]: (state, action) => {
    state.loading = false;
    state.error = action.payload; // Handle listing error
  },

  // Get transaction details
  [getTransactionDetails.trigger]: (state) => {
    state.getTransactionDetails.loading = true;
    state.getTransactionDetails.error = null;
  },
  [getTransactionDetails.resolved]: (state, action) => {
    state.getTransactionDetails.loading = false;
    state.getTransactionDetails.data = action.payload; // Set fetched transaction details
  },
  [getTransactionDetails.rejected]: (state, action) => {
    state.getTransactionDetails.loading = false;
    state.getTransactionDetails.error = action.payload; // Handle error for getting transaction details
  },
});

export default transactionsReducer;
