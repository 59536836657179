// api/services.js

import apiClient from './apiClient';

// List services
export const listServicesRequest = (payload) => {
  const {type: serviceType, ...body} = payload || {};

  const type = serviceType?.toLowerCase();

  const data = type === "services"? {} :  {
    category_slug: type
  }

  return apiClient({
    method: 'POST',
    url: type === "services"? "services/categories/list" : "services",
    data: {
      ...data,
      ...body
    }
  });
}

// Filter services
export const filterServicesRequest = (filterParams) =>
  apiClient({
    method: 'POST',
    url: '/services/filter',
    data: filterParams,
  });

// Search services
export const searchServicesRequest = (searchParams) =>
  apiClient({
    method: 'GET',
    url: '/services/search',
    params: searchParams,
  });

// Export services
export const exportServicesRequest = (exportParams) =>
  apiClient({
    method: 'POST',
    url: '/services/export',
    data: exportParams,
  });

// Switch provider
export const switchProviderRequest = (payload) =>
  apiClient({
    method: 'POST',
    url: '/services/switch-provider',
    data: payload,
  });

