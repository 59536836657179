import React from "react";
import SapaButton from "../../../../ui/Button/Button";
import TraceTabsHelper from "./TraceTabsHelper";

const TransactionTrace = () => {
  return (
    <div className="transaction-trace-container">
      <div className="transaction-trace">
        {/* <div className="transaction-trace__top-section">
          <span>
            <p>API Provider</p>
            <p className="modal-list-dark-text">Airvend</p>
          </span>
          <span>
            <p>PROVIDER BALANCE</p>
            <p className="modal-list-dark-text">₦ 10,000,000</p>
          </span>
          <span>
            <p>STATUS</p>
            <p className="warning-text">Failed</p>
          </span>
        </div>
        <div className="transaction-trace__lower-section">
          <TraceTabsHelper />
        </div> */}
      </div>
      <div className="transaction-modal-btn__container">
        <SapaButton
          label={"Download"}
          shape="rounded"
          buttonType="primary"
        />
        <SapaButton
          label={"Refund"}
          shape="rounded"
          buttonType="transparent"
        />
      </div>
    </div>
  );
};

export default TransactionTrace;
