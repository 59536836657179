const servicesIcon = ({ fill = "#2C264B" }) => (
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M10.7467 15.8607C10.7101 15.7631 10.6168 15.6987 10.5126 15.6968C9.53032 15.6792 8.56657 15.5107 7.65263 15.1317C6.97999 14.8506 6.25851 14.4702 5.69902 13.966C5.56996 13.8497 5.36023 13.8787 5.28087 14.0332L3.86097 16.7983C3.67763 17.1733 3.6943 17.6067 3.91097 17.9567C4.12763 18.315 4.50263 18.5233 4.9193 18.5233H10.036C10.4193 18.5233 10.786 18.34 11.0026 18.0233C11.2276 17.6983 11.2776 17.29 11.1443 16.9233L10.7467 15.8607Z" fill={fill}/>
<path fillRule="evenodd" clipRule="evenodd" d="M11.2258 5.94169C12.0125 6.13836 12.5825 6.70419 12.7491 7.45419C12.8141 7.74586 13.0725 7.94336 13.3591 7.94336C13.4041 7.94336 13.45 7.93836 13.4958 7.92836C13.8325 7.85336 14.045 7.52003 13.97 7.18253C13.7016 5.97836 12.7658 5.03836 11.5283 4.72919C11.1975 4.64753 10.855 4.84919 10.7708 5.18336C10.6875 5.51836 10.8908 5.85753 11.2258 5.94169Z" fill={fill}/>
<path fillRule="evenodd" clipRule="evenodd" d="M16.2453 6.23135C15.7686 4.35302 14.3111 2.92052 12.4428 2.49302C12.112 2.41469 11.7711 2.62635 11.6936 2.96219C11.617 3.29885 11.827 3.63385 12.1645 3.71135C13.5728 4.03385 14.6728 5.11719 15.0345 6.53885C15.1061 6.82219 15.3603 7.01052 15.6386 7.01052C15.6903 7.01052 15.742 7.00385 15.7936 6.99135C16.1278 6.90635 16.3311 6.56552 16.2453 6.23135Z" fill={fill}/>
<path opacity="0.4" fillRule="evenodd" clipRule="evenodd" d="M6.98011 4.04001C6.72678 3.78584 6.38761 3.64917 6.03094 3.67751C5.70428 3.70001 5.41428 3.85501 5.21511 4.11584C4.86511 4.57667 4.57344 5.08084 4.34928 5.61834C4.01178 6.43834 3.84094 7.30251 3.84094 8.18501C3.84094 9.06167 4.01178 9.9225 4.35011 10.745C4.68928 11.5592 5.17761 12.2883 5.80094 12.9125C6.41844 13.5317 7.14928 14.0192 7.97011 14.3608C8.77925 14.6975 9.64008 14.8683 10.5318 14.8683C11.4226 14.8683 12.2843 14.6975 13.0926 14.3617C13.6193 14.1442 14.1234 13.8542 14.5968 13.4975C14.8576 13.295 15.0134 13.0025 15.0343 12.6717C15.0559 12.3258 14.9243 11.9833 14.6734 11.7317L6.98011 4.04001Z" fill={fill}/>
</svg>

);

export default servicesIcon;
