import { Dropdown, Menu } from "antd";
import React, { useContext, useState } from "react";
import icon from "../../assets/svgs/Info menu.svg";
import TransactionModal from "../../components/Modal/TransactionModals/TransactionModal";
import { SapaContext } from "../../context/Context";

const TransactionsTable = () => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };
  const menu = (
    <Menu className="filter-transaction-items">
      <Menu.Item key={Math.random().toString()}>
        <p onClick={showDrawer}>View details</p>
      </Menu.Item>
      <Menu.Item key={Math.random().toString()}>
        <p>Print receipt</p>
      </Menu.Item>
      <Menu.Item key={Math.random().toString()}>
        <p className="warning-text">Authorize refund</p>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu}>
        <button className="table-action-btn">
          <img src={icon} alt="/" />
        </button>
      </Dropdown>
      <TransactionModal open={open} onClose={onClose} />
    </>
  );
};

const UsersTable = () => {
  const { setShowOverview } = useContext(SapaContext);
  const { setActiveTab } = useContext(SapaContext);

  const handleTransactionOpen = () => {
    setShowOverview(true);
    setActiveTab(2);
  };

  const menu = (
    <Menu className="filter-transaction-items">
      <Menu.Item key={Math.random().toString()}>
        <p onClick={() => setShowOverview(true)}>Edit profile</p>
      </Menu.Item>
      <Menu.Item key={Math.random().toString()}>
        <p onClick={handleTransactionOpen}>View transactions</p>
      </Menu.Item>
      <Menu.Item key={Math.random().toString()}>
        <p className="warning-text">Block user</p>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu}>
        <button className="table-action-btn">
          <img src={icon} alt="/" />
        </button>
      </Dropdown>
    </>
  );
};

const administratorsTable = () => {
  const menu = (
    <Menu className="filter-transaction-items">
      <Menu.Item key={Math.random().toString()}>
        <p>Edit profile</p>
      </Menu.Item>
      <Menu.Item key={Math.random().toString()}>
        <p className="warning-text">Block admin</p>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      <Dropdown overlay={menu}>
        <button className="table-action-btn">
          <img src={icon} alt="/" />
        </button>
      </Dropdown>
    </>
  );
};

const TableActionBtn = ({ table }) => {
  let Btn;
  switch (table) {
    case "transactionsTable":
      Btn = TransactionsTable;
      break;
    case "usersTable":
      Btn = UsersTable;
      break;
    case "administratorsTable":
      Btn = administratorsTable;
      break;
    default:
      Btn = TransactionsTable;
  }

  return <Btn />;
};

export default TableActionBtn;
