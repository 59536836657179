// sagas/authSaga.js

import { call, all, takeEvery } from 'redux-saga/effects';
import { loginRequest, refreshTokenRequest, logoutRequest } from './../../api/auth'; // API imports
import { login, refreshToken, logout } from '../actions/auth'; // Promise actions
import { resolvePromiseAction, rejectPromiseAction } from '@adobe/redux-saga-promise';
import { setAccessToken, translateErrorResponse } from '../../utils/utils';

// Saga to handle login
export function* handleLogin(action) {
  try {
    const response = yield call(loginRequest, action.payload);
    
    yield setAccessToken(response?.data?.data?.access_token);
    yield call(resolvePromiseAction, action, response?.data ?? {}); // Resolve the promise action

  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle refresh token
export function* handleRefreshToken(action) {
  try {
    const response = yield call(refreshTokenRequest);
    
    yield setAccessToken(response?.data?.data?.access_token);
    yield call(resolvePromiseAction, action, response?.data?.data ?? {}); // Resolve the promise action

  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Saga to handle logout
export function* handleLogout(action) {
  try {
    // Optional: You can make an API call for logging out if needed
    // const response = yield call(logoutRequest);

    yield setAccessToken(); // Remove access token on logout
    yield call(resolvePromiseAction, action, {}); // Resolve the promise action

  } catch (err) {
    const errorMessage = translateErrorResponse(err).message;
    yield call(rejectPromiseAction, action, errorMessage); // Reject the promise action
  }
}

// Root saga to watch for auth actions
export default function* authSaga() {
  yield all([
    takeEvery(login.trigger, handleLogin), // Watch for login action
    takeEvery(refreshToken.trigger, handleRefreshToken), // Watch for refresh token action
    takeEvery(logout.trigger, handleLogout), // Watch for logout action
  ]);
}
